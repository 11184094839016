<template>
  <div ref="vosParent">
    <div class="sort-header">
      <div class="category-title">Net Promoter Score (NPS)</div>
    </div>
    <div>
      <div
        class="stat-container"
        v-show="!isNoData">
        <div class="category-container">
          <div class="voc-card-container">
            <div
              v-for="(product, index) in sortedProducts"
              :key="index"
              :id="'id' + product.id"
              class="psudo-container">
              <VocCard :product="product" @noData="setNoData(true)" @data="setNoData(false)" :npsCard="true" />
            </div>
          </div>
        </div>
      </div>
      <NoDataContainerVue v-show="isNoData" class="mt-5" />
    </div>
  </div>
</template>

<script>
import VocCard from '@/components/vocSummary/VocCard.vue'
import NoDataContainerVue from '@/components/Common/NoDataContainer/NoDataContainer.vue'
import { metaTitle } from '../../constant/data'
import { mapState, mapActions } from 'vuex'

/** This is the parent component/view for the **NPS** page. */
export default {
  name: 'NPSPage',
  metaInfo () {
    return {
      title: metaTitle.npsPage
    }
  },
  components: {
    VocCard,
    NoDataContainerVue
  },
  data () {
    return {
      isNoData: true,
      showDisplayOption: true
    }
  },
  computed: {
    ...mapState('filters', [
      'selectedProducts',
      'subCategories',
      'categoryList',
      'loading',
      'productList',
      'selectedSourceList',
      'ActiveVocTimePeriod',
      'selectedIndustries',
      'selectedCompanies',
      'selectedCategories'
    ]),
    ...mapState('vocsummary', [
      'reviewVolume',
      'vocStatictics',
      'sortedProducts',
      'sort',
      'sortOptions'
    ]),
    ...mapState('user', ['userDetails']),
    watchable () {
      return JSON.stringify(this.selectedSourceList) + JSON.stringify(this.selectedIndustries) + JSON.stringify(this.selectedCompanies) + JSON.stringify(this.selectedProducts) + JSON.stringify(this.ActiveVocTimePeriod) + JSON.stringify(this.sort)
    }
  },
  watch: {
    watchable () {
      if (this.watchable.length) {
        this.getReviewVolume(true)
      }
    }
    // vocStatictics () {
    //   // console.log(this.vocStatictics)
    // },
    // selectedSourceList () {
    //   this.getReviewVolume(true)
    // },
    // selectedProducts () {
    //   this.getReviewVolume(true)
    // },
    // selectedIndustries () {
    //   this.getReviewVolume(true)
    // },
    // selectedCompanies () {
    //   this.getReviewVolume(true)
    // },
    // ActiveVocTimePeriod () {
    //   this.getReviewVolume(true)
    // },
    // sort () {
    //   this.getReviewVolume(false)
    // }
  },
  mounted () {
    this.getReviewVolume(true)
  },
  methods: {
    ...mapActions('vocsummary', [
      'getVocVolume',
      'getSortedProducts',
      'updateSort',
      'updateSortOptions'
    ]),
    /**
     * This method is used to update the sorting for the drivers. It is only used in the table views
     * @param data {Object} - eg. { type: 'review_id', orderBy: 'asc' }
     * @public
     */
    onChangeSelection (data) {
      this.updateSort(data)
    },
    setNoData (value) {
      this.isNoData = value
    },
    /**
     * This method gets the data required to render the VocCards on this page. If sufficient data is not available, the *NoData* container is rendered instead.
     * @public
     */
    async getReviewVolume (loadVolume) {
      // console.log(this.selectedCategories)
      if (
        this.selectedSourceList.length &&
        this.selectedProducts.length &&
        this.subCategories.length &&
        this.selectedIndustries.length &&
        this.selectedCompanies.length &&
        this.ActiveVocTimePeriod &&
        this.ActiveVocTimePeriod.group_by
      ) {
        const data = {
          source: this.selectedSourceList.map((item) => item.id),
          products: this.selectedProducts,
          categories: this.subCategories,
          industries: this.selectedIndustries.map((item) => item.id),
          companies: this.selectedCompanies.map((item) => item.id),
          clientId: this.userDetails.clientId,
          startTime: this.ActiveVocTimePeriod.range[0],
          endTime: this.ActiveVocTimePeriod.range[1],
          groupBy: this.ActiveVocTimePeriod.group_by,
          product: this.product,
          sort: this.sort,
          subscriptionId: this.selectedCategories.subscriptionId,
          // requestingModuleId: this.userDetails.modules.filter(m => m.module_name === 'voc')[0].module_id
          requestingModuleId: this.userDetails.modules.find(m => m.module_name === 'voc').module_id
        }
        this.getSortedProducts(data)
        if (loadVolume) {
          const res = await this.getVocVolume(data)
          this.vocData = res
        }
        /**
         * This event is used to signify that the minimum data needed to render the view was successfully retrieved from the database.
         */
        this.$emit('data')
      } else {
        this.isNoData = true
        /**
         * This event is triggered when the minimum data needed to render the view is not available.
         */
        this.$emit('noData')
      }
    }
  }
}
</script>

<style lang="scss" src="./NPS.scss" scoped/>
